<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #transfer-deposit-to-cash>
        <TransferDepositToCash />
      </template>
      <template #other-collect>
        <OtherCollect />
      </template>
    </TTabs>
  </div>
</template>

<script>
import TransferDepositToCash from "./TransferDepositToCash.vue";
import OtherCollect from "./OtherCollect.vue";

export default {
  components: {
    TransferDepositToCash,
    OtherCollect
  },
  data() {
    return {
      tabs: [
        {
          key: "transfer-deposit-to-cash",
          name: "Transfer deposit to cash",
          icon: "cisLibraryBooks",
        },
        {
          key: "other-collect",
          name: "Other collect",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
